import React from "react";
import EnergySidebar from "./EnergySidebar";
import ElectricalSidebar from "./ElectricalSidebar";
import MechanicalSidebar from "./MechanicalSidebar";
import SteelSidebar from "./SteelSidebar";
import OtherSidebar from "./OtherSidebar";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { RK_DIVISION_KEY, USER_BUSINESS_UNIT } from "../../constants/userInfo/userBusinessUnit";

function Sidebar({userData}){
    const location = useLocation();

    const { mechanical, energy, electrical, steel } = USER_BUSINESS_UNIT;
    const rkDivision = localStorage.getItem(RK_DIVISION_KEY) ?? USER_BUSINESS_UNIT.mechanical;

    // Hide sidebar when on login screen
    if(isEmpty(userData) || location.pathname?.includes('/login')){
        return <></>;
    }
    
    // Conditional rendering for sidebar
    if (rkDivision?.includes(electrical)) {
        return <ElectricalSidebar/> 
    } else if(rkDivision?.includes(energy)){
        return <EnergySidebar/> 
    } else if (rkDivision?.includes(mechanical)){
        return <MechanicalSidebar />
    } else if (rkDivision?.includes(steel)){
        return <SteelSidebar />
    }
 
    return (
        <OtherSidebar />
    )
}

const mapStateToProps = (state) => {
    return {
      userData: state.userReducer,
    };
};
  

export default connect(mapStateToProps)(Sidebar);